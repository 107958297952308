import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, Modal } from "antd";
const notionIframe = "https://cherifselfcity.github.io/Diagnosis-Assistant";
export const FloatingNotionButton = ({ username, clientRequestId }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const iframeSrc = `${notionIframe}?cc=${username}&missionId=${clientRequestId}`;
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                    position: 'fixed',
                    left: '20px',
                    bottom: '20px',
                    zIndex: 1000,
                }, children: _jsx(Button, { type: "primary", shape: "circle", onClick: showModal, size: "large", style: {
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                    }, children: "\uD83E\uDD16" }) }), isModalOpen && (_jsxs(Modal, { onCancel: handleCancel, footer: null, width: "90vw", visible: isModalOpen, bodyStyle: { padding: 0 }, className: "notion-iframe-modal", children: [_jsx("iframe", { src: iframeSrc, style: {
                            width: '100%',
                            height: '85vh',
                            border: 0,
                            padding: 0,
                        }, allowFullScreen: true, loading: "lazy", referrerPolicy: "no-referrer-when-downgrade", id: "notion-iframe" }), _jsx("hr", {}), _jsxs("div", { style: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '12px 24px',
                        }, children: [_jsx(Button, { onClick: () => {
                                    const iframe = document.getElementById('notion-iframe');
                                    if (iframe) {
                                        iframe.src = iframeSrc;
                                    }
                                }, children: "\uD83E\uDD16" }), _jsx(Button, { onClick: () => {
                                    handleCancel();
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 300);
                                }, children: "Fermer & actualiser" })] })] }))] }));
};
