import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { useUIComponents, use, useTranslate, useRouter, useGuardian, } from "@bluelibs/x-ui";
import { ClientRequestEditForm } from "../../config/ClientRequestEditForm";
import { ClientRequestJobEstimationsCard } from "../../../../components/ClientRequestJobEstimationsCard/ClientRequestJobEstimationsCard.index";
import { ClientRequestSumupCard } from "../../../../components/ClientRequestSumupCard/ClientRequestSumupCard.index";
import { ClientRequestTimeLine } from "@bundles/UIAppBundle/components/ClientRequestTimeLine/ClientRequestTimeLine.index";
import { AddComment } from "@bundles/UIAppBundle/components/Comments/AddComment";
import { FileTextOutlined, InboxOutlined, TeamOutlined, } from "@ant-design/icons";
import { ClientRequestQuotationsCard } from "@bundles/UIAppBundle/components/ClientRequestQuotationsCard/ClientRequestQuotationsCard.index";
import { ContactsAndLocationCard } from "@bundles/UIAppBundle/components/ContactsAndLocationCard/ContactsAndLocationCard.index";
import { FloatingActionButton } from "@bundles/UIAppBundle/components/FloatingActionButton/FloatingActionButton.index";
import { ClientRequestRelatedContactsCollection, ClientRequestsCollection, CommentsCollection, InboxTasksCollection, } from "@bundles/UIAppBundle/collections";
import { InboxTaskFormModal } from "@bundles/UIAppBundle/pages/InboxTasksManagement/components/List/InboxTaskFormModal.index";
import { useOpenModal, useSimpleModal } from "@root/utils/hooks";
import { IncidentReportModal } from "@bundles/UIAppBundle/components/IncidentReportModal/IncidentReportModal.index";
import { ClientRequestInvoicesCard } from "@bundles/UIAppBundle/components/ClientRequestInvoicesCard/ClientRequestInvoicesCard.index";
import { Routes } from "@bundles/UIAppBundle";
import { ClientRequestArchivedCard } from "@bundles/UIAppBundle/components/ClientRequestArchivedCard/ClientRequestArchivedCard.index";
import { ClientRequestFollowUpCard } from "@bundles/UIAppBundle/components/ClientRequestArchivedCard/ClientRequestFollowUpCard.index";
import InboxTask from "@bundles/UIAppBundle/pages/InboxTasksManagement/components/List/InboxTask";
import React, { useCallback, useState } from "react";
import { mutate } from "@root/store";
import { useParams } from "react-router-dom";
import { ClientRequestPossibleStatus, CommentVisibility, } from "@root/api.types";
import { FloatingNotionButton } from "@bundles/UIAppBundle/components/NotionIframe";
import axios from "axios";
import { ClientRequestPackagesCard } from "@bundles/UIAppBundle/components/ClientRequestJobEstimationsCard/ClientRequestPackagesCard.index.";
const body = {
    _id: 1,
    title: 1,
    done: 1,
    dueDate: 1,
    priority: 1,
    assignedToId: 1,
    clientRequestId: 1,
    tag: {
        _id: 1,
        name: 1,
    },
    clientRequest: {
        _id: 1,
        reference: 1,
    },
};
const options = {
    sort: {
        dueDate: 1,
        priority: -1,
    },
};
function redirectToBoilerService() {
    const token = window.localStorage.getItem("bluelibs-token");
    axios.request({
        method: "GET",
        headers: { "bluelibs-token": token },
        url: `${process.env.API_URL.replace("/graphql", "").concat('/ongoing-call')}`,
    }).then((response) => {
        window.open(`https://form.jotform.com/231022121025028${response.data.phone ? `?src=ringover&cmp=${response.data.phone}` : ''}`, '_blank');
    });
}
export function ClientRequestsEdit(props) {
    const UIComponents = useUIComponents();
    const t = useTranslate();
    const guardian = useGuardian();
    const { user } = guardian.state;
    const [je, setJe] = React.useState(null);
    const [quotes, setQuotes] = React.useState(null);
    const [invoices, setInvoices] = React.useState(null);
    const [packages, setPackages] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [tripLists, setTripLists] = React.useState(null);
    const form = use(ClientRequestEditForm, { transient: true });
    const clientRequestsCollection = use(ClientRequestsCollection);
    const relatedContactsCollection = use(ClientRequestRelatedContactsCollection);
    const commentsCollection = use(CommentsCollection);
    const inboxTasksCollection = use(InboxTasksCollection);
    const params = useParams();
    const onTechChoiceBtn = React.useCallback((clientRequestId) => {
        mutate("modal", "SHOW", {
            component: "techChoice",
            componentProps: { clientRequestId },
        });
    }, []);
    const openOnSiteJobModal = React.useCallback((clientRequestId) => {
        const token = window.localStorage.getItem("bluelibs-token");
        window.location.href = global.NEW_APP_URL + '/missions/' + clientRequestId + '/onSiteJobs/create?token=' + token;
    }, []);
    const router = useRouter();
    const onReload = () => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 250);
    };
    // Quickfix that prevents modal from emitting errors because form is already consumed.
    const [key, setKey] = useState("___");
    const reRender = (newKey) => {
        if (key !== newKey) {
            setKey(null);
            setTimeout(() => {
                setKey(newKey);
            }, 500);
        }
    };
    const { openModal: openIncdntRprtModal, closeModal: closeIncdntRprtModal, isVisible: isIncdntRprtVisible, } = useSimpleModal();
    const openModal = useOpenModal("inboxTask");
    const { data: document, loading, 
    // refetch,
    error, } = clientRequestsCollection.useQueryOne({
        body: ClientRequestEditForm.getRequestBody(),
        queryInput: {
            filters: { _id: new ObjectId(props.id) },
        },
    });
    const { data: clientRequestTasks, refetch: refetchClientRequestTasks } = inboxTasksCollection.useQuery({
        body,
        queryInput: {
            filters: {
                clientRequestId: document ? new ObjectId(document._id) : undefined,
                done: { $not: { $eq: true } },
            },
            options,
        },
    });
    const toggleAssigned = useCallback((taskId) => async () => {
        await inboxTasksCollection.updateOne(taskId, {
            assignedToId: user === null || user === void 0 ? void 0 : user._id,
        });
        refetchClientRequestTasks();
    }, [user === null || user === void 0 ? void 0 : user._id]);
    const toggleFollowUps = useCallback((date) => async () => {
        await clientRequestsCollection.updateOne(document._id, {
            nextFollowUpDate: document.nextFollowUpDate === null ? date : null,
        });
    }, [document]);
    const markAsDone = useCallback((taskId, tagId) => async () => {
        if ([
            '6283d25dec50674d56a99af7',
            '637de8068bdf164cf4979714',
            '6392fc8a74867020b7bb4bb0',
        ].includes(`${tagId}`)) {
            const lastJob = tripLists.filter((job) => { var _a; return ((_a = job.data.report) === null || _a === void 0 ? void 0 : _a._isProcessed) === false; }).sort((a, b) => new Date(b.data._createdAt).getTime() - new Date(a.data._createdAt).getTime());
            if (lastJob[0]) {
                const submit = async () => {
                    const token = window.localStorage.getItem("bluelibs-token");
                    await axios.request({
                        method: 'POST',
                        url: global.NEW_API_URL + '/onSiteJobs/' + lastJob[0].data._id + '/process',
                        headers: { "Authorization": token },
                    });
                    await axios.request({
                        method: 'GET',
                        url: (window.location.host === 'belett2.selfcity.fr' || window.location.host === 'staging.belett.selfcity.fr' ? '' : '') + 'https://n8n.selfcity.fr/webhook/d88a4103-dbaf-47d6-818f-255aea04c4bd',
                        params: {
                            onSiteJob: lastJob[0].data._id,
                            mission: lastJob[0].data._mission,
                            env: window.location.host === 'belett2.selfcity.fr' ? 'production' : 'preproduction',
                        },
                    });
                    await inboxTasksCollection.updateOne(taskId, { done: true });
                    window.location.reload();
                };
                Ant.Modal.confirm({
                    okText: "Confirmer",
                    cancelText: "Annuler",
                    content: _jsx("div", { children: 'Le compte-rendu sera envoyé au client et à l\'agence. Il ne sera plus possible de modifier celui-ci. Es-tu sûr(e) ?' }),
                    onOk: submit,
                    onCancel: () => window.location.reload(),
                });
            }
            else {
                await inboxTasksCollection.updateOne(taskId, { done: true });
                refetchClientRequestTasks();
            }
        }
        else {
            await inboxTasksCollection.updateOne(taskId, { done: true });
            refetchClientRequestTasks();
        }
    }, [inboxTasksCollection, tripLists, refetchClientRequestTasks]);
    form.build();
    React.useEffect(() => {
        if (`${document === null || document === void 0 ? void 0 : document._id}` !== 'undefined') {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: 'POST',
                url: global.NEW_API_URL + '/estimates/search?limit=100&fields=*,categories.subject.*,categories.tools,categories.tasks,categories.supplies,assignedTo.firstName,assignedTo.lastName',
                headers: { "Authorization": token },
                data: {
                    filters: {
                        _mission: `${document._id}`,
                    },
                },
            }).then((response) => {
                axios.request({
                    method: 'GET',
                    url: global.NEW_API_URL + '/tools?limit=100&fields=*',
                    headers: { "Authorization": token },
                }).then((response2) => {
                    const mapping = response2.data.results.reduce((acc, tool) => ({
                        ...acc,
                        [String(tool._id)]: tool.name,
                    }), {});
                    setJe(response.data.results.map((result) => ({
                        ...result,
                        categories: result.categories.map((cat) => ({
                            ...cat,
                            tools: cat.tools.map((tool) => ({
                                ...tool,
                                tool: mapping[String(tool.tool)],
                            })),
                        })),
                    })));
                });
            });
        }
    }, [`${document === null || document === void 0 ? void 0 : document._id}`, reload]);
    React.useEffect(() => {
        if (`${document === null || document === void 0 ? void 0 : document._id}` !== 'undefined') {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: "POST",
                url: global.NEW_API_URL + '/onSiteJobs/search?fields=*,appointments.*,appointments.user.firstName,appointments.user.lastName',
                headers: { "Authorization": token },
                data: {
                    filters: { _mission: `${document === null || document === void 0 ? void 0 : document._id}` },
                },
            }).then((response) => {
                setTripLists(response.data.results.map((result) => ({
                    __typename: 'TripList',
                    data: result,
                })));
            });
        }
    }, [`${document === null || document === void 0 ? void 0 : document._id}`, reload]);
    React.useEffect(() => {
        if (`${document === null || document === void 0 ? void 0 : document._id}` !== 'undefined') {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: 'POST',
                url: global.NEW_API_URL + '/packages/search?limit=100&fields=*',
                headers: { "Authorization": token },
                data: {
                    filters: {
                        _mission: `${document._id}`,
                    },
                },
            }).then((response) => {
                setPackages(response.data.results);
            });
        }
    }, [`${document === null || document === void 0 ? void 0 : document._id}`, reload]);
    React.useEffect(() => {
        if (`${document === null || document === void 0 ? void 0 : document._id}` !== 'undefined') {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: 'POST',
                url: global.NEW_API_URL + '/quotes/search?fields=*,_estimate.assignedTo.firstName,_estimate.assignedTo.lastName',
                headers: { "Authorization": token },
                data: {
                    filters: {
                        _mission: `${document._id}`,
                    },
                },
            }).then((response) => {
                setQuotes(response.data.results);
            });
        }
    }, [`${document === null || document === void 0 ? void 0 : document._id}`, reload]);
    React.useEffect(() => {
        if (`${document === null || document === void 0 ? void 0 : document._id}` !== 'undefined') {
            const token = window.localStorage.getItem("bluelibs-token");
            axios.request({
                method: 'POST',
                url: global.NEW_API_URL + '/invoices/search?fields=*,packages._name,quotes._number',
                headers: { "Authorization": token },
                data: {
                    filters: {
                        _mission: `${document._id}`,
                    },
                },
            }).then((response) => {
                setInvoices(response.data.results);
            });
        }
    }, [`${document === null || document === void 0 ? void 0 : document._id}`, reload]);
    /* Remove empty Client Request */
    // const isEmptyClientRequest = useRef(false);
    // useEffect(() => {
    //   isEmptyClientRequest.current =
    //     !document?.jobEstimations &&
    //     (!document?.comments || document?.comments?.length <= 1) &&
    //     !document?.relatedContacts &&
    //     (!document?.description ||
    //       document?.description.replace(/\s/g, "") === "");
    // }, [document]);
    // // onCloseTab
    // window.addEventListener("beforeunload", function (e) {
    //   if (isEmptyClientRequest.current) {
    //     clientRequestsCollection.deleteOne(props.id);
    //   }
    // });
    // // onPageChange & onBackHistory
    // useEffect(() => {
    //   return () => {
    //     if (isEmptyClientRequest.current) {
    //       clientRequestsCollection.deleteOne(props.id).then(() => {
    //         Ant.notification.info({
    //           message: "Info",
    //           description: "Mission vide supprimé",
    //         });
    //       });
    //     }
    //   };
    // }, []);
    const duplicateClientRequest = async (oldClientRequest) => {
        var _a, _b, _c, _d, _e, _f, _g;
        const newClientRequest = await clientRequestsCollection.insertOne({
            status: ClientRequestPossibleStatus.S_00100_CLIENT_REQUEST,
            sourceLead: oldClientRequest.sourceLead,
        });
        const newRelatedContacts = await Promise.all((_a = oldClientRequest.relatedContacts) === null || _a === void 0 ? void 0 : _a.map(async (rc) => await relatedContactsCollection.insertOne({
            clientRequestId: newClientRequest._id,
            profileType: rc.profileType,
            locationIndex: rc.locationIndex,
            isPayer: rc.isPayer,
            isLocation: rc.isLocation,
            isAsker: rc.isAsker,
            // distanceFromPlaces: rc.distanceFromPlaces, // TODO : graphql problem
            profileId: rc.profileId,
            agencyId: rc.agencyId,
        }, {
            refetchBody: {
                distanceFromPlaces: {
                    distance: 1,
                    duration: 1,
                    placeId: 1,
                    placeName: 1,
                },
            },
        })));
        const comment1 = await insertComment(newClientRequest._id, `Mission dupliquée à partir de cette mission: ${window.location.href}`);
        const comment2 = !!((_d = (_c = (_b = oldClientRequest.tripLists) === null || _b === void 0 ? void 0 : _b.at(-1)) === null || _c === void 0 ? void 0 : _c.report) === null || _d === void 0 ? void 0 : _d.notes) &&
            (await insertComment(newClientRequest._id, `Notes du dernier CR de cette mission :\n${(_g = (_f = (_e = oldClientRequest.tripLists) === null || _e === void 0 ? void 0 : _e.at(-1)) === null || _f === void 0 ? void 0 : _f.report) === null || _g === void 0 ? void 0 : _g.notes}`, oldClientRequest.tripLists.at(-1).report.attachmentsId));
        const comment3 = await insertComment(oldClientRequest._id, `Une nouvelle mission a été crée automatiquement : ${window.location.origin +
            router.path(Routes.CLIENT_REQUESTS_EDIT, {
                params: { id: newClientRequest._id.toString() },
            })}`);
        return newClientRequest;
    };
    const insertComment = async (clientRequestId, text, attachmentsId) => {
        const commentContent = {
            text,
            attachmentsId,
            visibility: CommentVisibility.PUBLIC,
            clientRequestId: new ObjectId(clientRequestId),
        };
        const comment = await commentsCollection.insertOne(commentContent, {
            apollo: { refetchQueries: ["CommentsFind"] },
            refetchBody: { _id: 1, attachments: { _id: 1 } },
        });
        return comment;
    };
    const isDisabled = (!(document === null || document === void 0 ? void 0 : document.sourceLead)
        || (document === null || document === void 0 ? void 0 : document.relatedContacts.find((contact) => contact.isLocation)) === undefined);
    return (_jsxs(UIComponents.AdminLayout, { children: [document && _jsx(FloatingNotionButton, { clientRequestId: document._id, username: user.profile.firstName }), !je || loading && !document && (_jsx(Ant.Space, { align: "center", children: _jsx(Ant.Spin, { size: "large" }) })), !loading && (error || !document) && (_jsx(Ant.Alert, { className: "mar-b-1", message: error || t("generics.error_message"), type: "error" })), (!loading || document) && !error && je && (_jsxs("div", { className: "flex page-client-requests-edit", children: [_jsxs("div", { className: "child-mar-b-1 mar-r-2 flex-14 cards-div", children: [document.isArchived && (_jsx(ClientRequestArchivedCard, { clientRequest: document })), document.nextFollowUp !== null && (_jsx(ClientRequestFollowUpCard, { clientRequest: document, toggleFollowUps: toggleFollowUps })), document && (_jsxs("div", { className: document.isArchived ? "disabled " : "", children: [_jsx(ClientRequestSumupCard, { clientRequest: document }), _jsx(ContactsAndLocationCard, { clientRequest: document }), reload ? null : _jsx(ClientRequestPackagesCard, { clientRequest: document, packages: packages, onReload: onReload }), reload ? null : _jsx(ClientRequestJobEstimationsCard, { clientRequest: document, estimates: je.filter((est) => !(quotes === null || quotes === void 0 ? void 0 : quotes.find((quote) => quote._estimate._id === est._id))), onReload: onReload }), reload ? null : _jsx(ClientRequestQuotationsCard, { clientRequest: document, quotes: quotes, onReload: onReload }), reload ? null : _jsx(ClientRequestInvoicesCard, { clientRequest: document, invoices: invoices, onReload: onReload })] }))] }), _jsxs("div", { className: "flex-8 timeline", children: [_jsx("div", { className: document.isArchived
                                    ? "disabled flex flex-col vgap-2"
                                    : "flex flex-col vgap-2", style: { marginBottom: "var(--gaps-2)" }, children: clientRequestTasks.map((task) => (_jsx(InboxTask, { _id: task._id, dueDate: task.dueDate, tagName: task.tag.name, title: task.title, activeUserId: user === null || user === void 0 ? void 0 : user._id, assignedToId: task.assignedToId, onClick: markAsDone(task._id, task.tag._id), unassigned: task.assignedToId === null, toggleAssigned: toggleAssigned(task._id), clientRequest: {
                                        _id: document._id,
                                        reference: document.reference,
                                    }, tooltips: {
                                        markAsDone: "",
                                        toggleAssigned: "",
                                    } }, task._id))) }), (tripLists === null || tripLists === void 0 ? void 0 : tripLists.find((job) => { return job.data.type === 'AFTER_SALE_SERVICE'; }))
                                ? (_jsx("div", { style: { textAlign: 'center' }, children: "Cette mission est pass\u00E9e en SAV, seuls de nouveaux SAV peuvent \u00EAtre planifi\u00E9s." }))
                                : (_jsx("div", { className: document.isArchived
                                        ? "disabled flex spaced gap-1"
                                        : "flex spaced gap-1", children: _jsx(Ant.Button, { type: "primary", className: document.isArchived || isDisabled
                                            ? "disabled blue-btn grow"
                                            : "blue-btn grow", style: isDisabled ? { pointerEvents: 'all', cursor: 'default' } : {}, icon: _jsx(TeamOutlined, {}), onClick: !isDisabled ? () => openOnSiteJobModal(props.id) : () => {
                                            alert('Certaines informations de cette mission sont manquantes pour planifier des interventions : le lieu d’inter, la source et les estimations (avec activité, sujet, type)');
                                        }, children: "Planifier une intervention" }) })), _jsx(AddComment, { oneLine: true, clientRequestId: props.id, className: document.isArchived ? "disabled mar-t-1" : "mar-t-1" }), _jsx(Ant.Divider, { className: "divider mar-v-1" }), _jsx(ClientRequestTimeLine, { clientRequestId: props.id, clientRequest: document, className: "main-timeline" }), _jsx(FloatingActionButton, { secondaryBtns: [
                                    {
                                        tooltip: t("management.client_requests.actions.report_incident"),
                                        icon: _jsx(FileTextOutlined, {}),
                                        onClick: openIncdntRprtModal,
                                    },
                                    {
                                        tooltip: t("management.client_requests.actions.create_task"),
                                        icon: _jsx(InboxOutlined, {}),
                                        onClick: () => openModal("new", { clientRequestId: document._id }),
                                    },
                                    {
                                        tooltip: 'Créer un nouvel entretien chaudière',
                                        icon: _jsx(InboxOutlined, {}),
                                        onClick: redirectToBoilerService,
                                    },
                                    {
                                        tooltip: t("management.client_requests.actions.create_new"),
                                        icon: _jsx(InboxOutlined, {}),
                                        onClick: () => {
                                            clientRequestsCollection
                                                .createNewClientRequest()
                                                .then((res) => {
                                                window.open(`/client-requests/${res._id.toString()}/edit`, "_blank");
                                            });
                                        },
                                    },
                                    {
                                        tooltip: t("management.client_requests.actions.duplicate"),
                                        icon: _jsx(InboxOutlined, {}),
                                        onClick: () => {
                                            duplicateClientRequest(document).then((newCR) => {
                                                window.open(`/client-requests/${newCR._id.toString()}/edit`, "_blank");
                                            });
                                        },
                                    },
                                ] }), _jsx(IncidentReportModal, { clientRequestId: document._id, isVisible: isIncdntRprtVisible, closeModal: closeIncdntRprtModal })] })] })), key === null ? null : (_jsx(InboxTaskFormModal, { isCRPage: true, onCreateSuccess: () => {
                    reRender(`_${Math.random()}`);
                    refetchClientRequestTasks();
                } }))] }));
}
